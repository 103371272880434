import { Controller } from '@hotwired/stimulus';
import { pinia } from 'stores'
import { mapState } from 'pinia'
import { createApp } from 'vue'
import { useChatStore } from 'stores/chat_store'

export default class extends Controller {
  initialize() {
    this.app = createApp({
      name: 'ChatStatus',
      compilerOptions: { isCustomElement: tag => tag.includes('-') },
      computed: mapState(useChatStore, ['isConnected', 'status', 'conversationCount']),
      methods: {
        hide(){
          $.me.chat.hide()
        },

        toggle(){
          $.me.chat.toggle()
        },

        showNewMessages(){
          $.me.chat.showNewMessages()
        }
      }
    }).use(pinia)
  }

  connect() {
    this.app.mount(this.element)
  }

  disconnect() {
    this.app.unmount()
  }
}
