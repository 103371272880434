/*
 <clearable-input data-controller="form--input--clearable">
 <input type="text" name="name" id="name" placeholder="ex: manon" />
 <button type="button"><font-icon class="cross"></font-icon></button>
 </clearable-input>
 */

import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

  button = null
  input = null
  wrapper = null

  connect() {
    this.button = this.element.querySelector('button')
    this.input = this.button.previousElementSibling
    this.wrapper = this.input.parentNode
    this.button.addEventListener('click', () => this.#clear())
  }

  #clear () {
    this.input.value = ''
    this.wrapper.dataset.content = ''
  }
}
