import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['panel']

  toggle(event) {
    const isHidden = !event.currentTarget.checked
    this.panelTarget.toggleAttribute('hidden', isHidden)
    isHidden && (this.panelTarget.querySelector('input').value = '')
  }
}
