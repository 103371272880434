import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  panels = null
  form = null

  connect() {
    this.form = this.element.querySelector('fieldset')
    this.panels = this.element.querySelectorAll('panel-buttons')
  }

  toggle() {
    this.panels.forEach(panel => panel.toggleAttribute('hidden'))
    this.form.toggleAttribute('disabled')
  }
}
