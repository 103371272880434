import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'list', 'button']
  static values  = {
    url: String,
    dataId: String,
    dataLabel: String,
    min: { type: Number, default: 3 }
  }

  static selectClasses = ['selected']

  connect() {
    const itemTemplate = this.listTarget.querySelector('template')
    this.itemTemplate = itemTemplate.content.cloneNode(true)
    itemTemplate.remove()

    this.buttonTarget.addEventListener('click', () => this.toggle())

    this.inputTarget.addEventListener('keyup', event => {
      switch (event.key) {
        case 'ArrowDown': this.selectFirst()
          break
        default:
          if (event.key.length != 1 && !['Backspace','Delete'].includes(event.key)) return
          this.search()
      }
    })

    this.listTarget.addEventListener('keydown', event => {
      event.preventDefault()
      switch (event.key) {
        case 'ArrowDown': this.selectNext()
          break
        case 'ArrowUp': this.selectPrevious()
          break
        case 'Enter': this.setSelected()
          break
      }
    })

    this.listTarget.addEventListener('click', event => {
      this.select(event.target)
      this.setSelected()
    })

    this.element.addEventListener('keydown', event => {
      if (event.key != 'Escape') return

      event.preventDefault()
      this.close()
    })

    document.addEventListener('click', event => this.closeOutside(event))

    this.search(false)
  }

  disconnect() {
    document.removeEventListener('click', event => this.closeOutside(event))
  }

  isOpen = () => !this.listTarget.classList.contains('hidden')

  open() {
    if (this.listTarget.childNodes.length === 0) return

    this.listTarget.classList.remove('hidden')
  }

  close() {
    this.listTarget.classList.add('hidden')
    this.unselect()
  }

  toggle = () => (this.isOpen() ? this.close() : this.open())

  closeOutside = event => !this.element.contains(event.target) && this.close()

  selectFirst() {
    const first = this.listTarget.firstElementChild
    if (!first) return

    this.open()
    this.select(first)
    this.listTarget.focus()
    this.listTarget.scrollTo(0,0)
  }

  selectPrevious() {
    const previous = this.selected()?.previousElementSibling
    if (!previous) {
      this.unselect()
      this.inputTarget.focus()
      return
    }

    this.select(previous)

    if (previous.getBoundingClientRect().top < this.listTarget.getBoundingClientRect().top)
      previous.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  selectNext() {
    const next = this.selected() ? this.selected().nextElementSibling : this.listTarget.firstElementChild
    if (!next) return

    this.select(next)

    if (next.getBoundingClientRect().bottom > this.listTarget.getBoundingClientRect().bottom)
      next.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  select(item) {
    this.unselect()
    item.classList.add(...this.constructor.selectClasses)
    item.dataset.selected = 'true'
    this.dispacthEvent('select', item, { 'id': item.getAttribute('data-auto-complete-item'), 'label': item.innerHTML })
  }

  selected = () => this.listTarget.querySelector('[data-selected]')

  unselect() {
    const item = this.selected()
    if (!item) return
    item.classList.remove(...this.constructor.selectClasses)
    delete item.dataset.selected
    this.dispacthEvent('unselect', item, { 'id': item.getAttribute('data-auto-complete-item'), 'label': item.innerHTML })
  }

  setSelected() {
    const item = this.selected()
    this.inputTarget.value = item.innerText
    this.listTarget.querySelectorAll('[data-auto-complete-item]:not([data-selected])').forEach(elmt => elmt.remove())
    this.close()
    this.inputTarget.focus()
    this.dispacthEvent('change', this.inputTarget, { 'id': item.getAttribute('data-auto-complete-item'), 'label': item.innerHTML })
  }

  search(openList=true) {
    if (this.inputTarget.value === '') {
      this.close()
      this.resetList()
      this.dispacthEvent('search', this.inputTarget, { 'search': '' })
      return
    } else if (this.inputTarget.value.length < this.minValue) {
      return
    }

    const url = new URL(this.urlValue)
    const searchParams = new URLSearchParams()
    searchParams.set('search', this.inputTarget.value)
    url.search = searchParams.toString()
    fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
    .then(response => response.json())
    .then(data => {
      this.resetList()

      if (data.length <= 0) {
        this.close()
        return
      }

      data.forEach(obj => this.addItem(obj[this.dataIdValue], obj[this.dataLabelValue]))
      if (openList) this.open()
    })
    this.dispacthEvent('search', this.inputTarget, { 'search': this.inputTarget.value })
  }

  addItem(id, label) {
    const itemNode = this.itemTemplate.cloneNode(true)
    const item = itemNode.querySelector('[data-auto-complete-item]')
    label = label.replace(new RegExp(`(${this.inputTarget.value})`, 'gi'), '<strong>$1</strong>')
    item.setAttribute('data-auto-complete-item', id)
    item.innerHTML = label
    this.listTarget.appendChild(itemNode)
  }

  resetList = () => this.listTarget.innerHTML = ''

  dispacthEvent(type, node, detail=null) {
    const event = new CustomEvent(`auto-complete:${type}`, { bubbles: true, detail: detail })
    node.dispatchEvent(event)
  }
}
