import { Controller } from '@hotwired/stimulus'
import emoticonize from 'libs/utils/emoticonize'

export default class extends Controller {
  connect() {
    this.element.addEventListener('keyup', this.#emoticonize)
  }

  #emoticonize(event) {
    const node = event.target
    let text = node.value
    let position = node.selectionStart - text.length
    text = emoticonize(text)
    position += text.length
    node.value = text
    node.setSelectionRange(position, position)
  }
}
