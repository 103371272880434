import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.observer = null
    this.checkData()
  }

  connect() {
    this.observer = new MutationObserver((_mutationList, _observer) => { this.checkData() })
    this.observer.observe(this.element, { childList: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  checkData() {
    this.element.setAttribute('no-data', this.element.childElementCount > 0)
  }
}
