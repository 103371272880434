const emojiPatterns = {
  '😊': ":\\\)",
  '😁': ":D",
  '😉': ";[\\\)D]{1}",
  '😂': ":'[\\\)D]{1}",
  '🤣': "[xX]{1}[\\\)D]{1}",
  '😝': ":p",
  '😍': "\\\<3",
  '😘': ":[\\\*x]{1}",
  '😮': ":[oO]{1}",
  '😐': ":\\\|",
  '😞': ":\\\(",
  '😢': ":'\\\(",
  '😡': ">:[\\\(\\\[]{1}",
  '😵‍💫': "%[|\\\)]{1}",
  '😸': "\\\^{2}"
}

const emojiMatchers = Object.fromEntries(
  Object.entries(emojiPatterns).map(
    ([emoji, pattern]) => [emoji, new RegExp(`(^|\\s)(${pattern})($|\\s)`, 'gi')]
  )
)

export default function emoticonize(text) {
  for (const [emoji, matcher] of Object.entries(emojiMatchers) ) {
    text = text.replace(matcher, `$1${emoji}$3`)
  }
  return text
}
