import { Controller } from '@hotwired/stimulus'
import Toast from 'libs/toast'

export default class extends Controller {

  connect() {
    new Toast(this.element.textContent, { type: this.element.getAttribute('type') }).show()
    this.element.remove()
  }
}
