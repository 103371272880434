import { defineStore } from 'pinia'

export const useCommonStore = defineStore('common', {
  state: () => {
    return {
      currentUser: null,
      location: null,
      device: 0
    }
  },
  actions: {
    isDesktopDevice() {
      this.device == 0
    },

    isMobileDevice() {
      this.device == 1
    },

    isTabletDevice() {
      this.device == 2
    }
  }
})
