import { Controller } from '@hotwired/stimulus';
import { createApp } from 'vue'
import userConsent from 'libs/user_consent'

export default class extends Controller {
  initialize() {
    this.app = createApp({
      name: 'UserConsent',
      compilerOptions: { isCustomElement: tag => tag.includes('-') },
      computed: {
        allConsents: {
          get() { return Object.values(this.consent).reduce((acc, cur) => (acc && cur), true) },
          set(value) { Object.keys(this.consent).forEach(key => this.consent[key] = value) }
        }
      },
      data() {
        return {
          bannerVisible: false,
          modalOpened: false,
          consent: userConsent.defaultConsent
        }
      },
      methods: {
        showDetails(){ this.modalOpened = true },

        hideDetails(){ this.modalOpened = false },

        save(){
          userConsent.setCookie(this.consent)
          userConsent.sendToGtm()
          this.hideDetails()
          this.bannerVisible = false
        },

        acceptAll(){
          this.allConsents = true
          this.save()
        },

        refuseAll(){
          this.allConsents = false
          this.save()
        }
      },
      mounted(){
        const consent = userConsent.getCookie()
        if (consent)
          this.consent = consent
        else
          this.bannerVisible = true

        this.$el.parentNode.addEventListener('me:open', () => this.modalOpened = true)
      }
    })
  }

  connect() {
    this.app.mount(this.element)
  }

  disconnect() {
    this.app.unmount()
  }
}
