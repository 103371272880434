import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { selector: String }

  connect() {
    this.element.addEventListener('change', () => this.#populate(event) )
  }

  #populate(event) {
    document.querySelector(this.selectorValue).value = event.target.value
  }
}
