import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['switch', 'panel']
  static checkboxes = null

  connect() {
    this.checkboxes = this.panelTarget.querySelectorAll('input[type="checkbox"]')

    this.switchTarget.addEventListener("change", () => this.#toggleSwitch() )
    this.checkboxes.forEach(checkbox => checkbox.addEventListener('change', () => this.#toggleType(event)))

    this.#toggleType()
  }

  disconnect() {
    this.switchTarget.removeEventListener("change", () => this.#toggleSwitch() )
    this.checkboxes.forEach(checkbox => checkbox.removeEventListener('change', () => this.#toggleType(event)))
  }

  #toggleSwitch () {
    const isChecked = this.switchTarget.checked
    this.panelTarget.toggleAttribute('hidden', !isChecked)
    this.checkboxes.forEach(checkbox => checkbox.checked = isChecked)
  }

  #toggleType() {
    const isSomeChecked = [...this.checkboxes].some(checkbox => checkbox.checked)
    this.panelTarget.toggleAttribute('hidden', !isSomeChecked)
    this.switchTarget.checked = isSomeChecked
  }
}
