import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    target: String,
    backPath: String
  }

  use(event) {
    const sentense = document.getElementById(event.currentTarget.dataset.target).innerText

    if (this.targetValue) {
      const target = document.getElementById(this.targetValue)
      const [start, end] = [target.selectionStart, target.selectionEnd]
      target.setRangeText(sentense, start, end, 'end')
      target.dispatchEvent(new Event('input'))
      target.focus()
    } else if(this.backPathValue) {
      const frame = this.element.closest('turbo-frame#modal')
      if (!frame) return

      frame.src = `${this.backPathValue}?text=${encodeURIComponent(sentense)}`
      frame.reload()
      event.stopImmediatePropagation()
    }
  }
}
