/*
  History management
*/
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // History
  addAnchorToCurrentState(event) {
    const anchor = event.currentTarget.dataset.anchor
    let url = new URL(window.location.href)
    url.hash = anchor
    // Use Turbo internals to generate a proper restoration state
    Turbo.navigator.history.update(history.replaceState, url)
  }
}
