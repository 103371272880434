import { StreamActions } from '@hotwired/turbo'

StreamActions.toggle_attribute = function() {
  const name = this.getAttribute('name')
  let force = this.getAttribute('force')?.toLowerCase()
  if (force) force = force === 'true'

  this.targetElements.forEach((e => e.toggleAttribute(name, force)))
}

StreamActions.set_attribute = function() {
  const name = this.getAttribute('name')
  const value = this.getAttribute('value')

  this.targetElements.forEach((e => e.setAttribute(name, value)))
}

StreamActions.toggle_css_class = function() {
  const classes = this.getAttribute('classes')
  let force = this.getAttribute('force')?.toLowerCase()
  if (force) force = force === 'true'

  this.targetElements.forEach((e => e.classList.toggle(classes, force)))
}
