/*
 <custom-dropdown data-controller="dropdown">
   <button data-action="dropdown#toggle">
    <font-icon class="more--fill icon-size-lg text-neutral-medium"></font-icon>
   </button>
   <dropdown-panel direction="bottom right" data-dropdown-target="panel">
   <ul>
     <li>
       <a href="#" class="icon+text-md">
        <font-icon class="trash"></font-icon> <%= t 'buttons.delete' %>
      </a>
     </li>
   </ul>
   </dropdown-panel>
 </custom-dropdown>

 - Open management with "open" attribute
 - Direction management with "direction" attribute. Cumalative values: top, right, bottom, left
*/
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['panel']

  connect() {
    document.addEventListener('click', () => this.#clickOutside(event));
  }

  disconnect() {
    document.removeEventListener('click', () => this.#clickOutside(event));
  }

  toggle() {
   this.element.toggleAttribute('open')
  }

  #clickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.element.toggleAttribute('open', false);
    }
  }
}
