import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    handle: String
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 350,
      onEnd: this.onEnd.bind(this),
      ...(this.hasHandleValue && { handle: this.handleValue })
    })
  }

  disconnect() {
    this.sortable.destroy();
  }

  onEnd(event) {
    const { newIndex, item } = event
    const url = item.dataset['sortableUrl']

    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify({ position: newIndex + 1 })
    })
  }
}