import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  slider = null
  slideWidth = 0
  slidesCount = 0
  interval = null
  index = 0
  buttons = null

  connect() {
    this.slider = this.element.querySelector('slider-stripe')
    this.slideWidth = this.element.clientWidth
    this.slidesCount = this.slider.querySelectorAll('tips-card').length

    if (this.slidesCount < 2) return

    this.#startAutoSlide()
    this.slider.addEventListener('touchstart', () => this.#stopAutoSlide())
    this.slider.addEventListener('scroll', () => this.#scrollHandler())
    this.slider.addEventListener('wheel', (event) => { if (Math.abs(event.deltaX) > 1) this.#stopAutoSlide() })

    this.#createSliderNav(this.slidesCount)
    this.buttons = this.element.querySelectorAll('slider-nav>button')
  }

  #scroll() {
    this.slider.scrollTo({
      left: this.index * this.slideWidth,
      behavior: 'smooth'
    })
  }

  #startAutoSlide() {
    this.interval = setInterval(() => this.#autoSlide(), 3000)
  }

  #stopAutoSlide() {
    clearInterval(this.interval)
  }

  #stopAutoSlideAfterLoop() {
    if (this.index === this.slidesCount - 1) this.#stopAutoSlide()
  }

  #autoSlide() {
    this.#stopAutoSlideAfterLoop()

    this.index = (this.index + 1) % this.buttons.length;

    this.#scroll()
    this.#updateButtonState()
  }

  #scrollHandler() {
    this.index = Math.round(this.slider.scrollLeft / this.slideWidth)
    this.#updateButtonState()
  }

  #buttonHandler(button, index) {
    button.index = index
    button.addEventListener('click', () => {
      this.index = button.index
      this.#stopAutoSlide()
      this.#scroll()
      this.#updateButtonState()
    })
  }

  #updateButtonState() {
    this.buttons.forEach((button, index) => button.toggleAttribute('active', index === this.index))
  }

  #createButton(label, active=false) {
    const button = document.createElement('button')
    if (active) button.setAttribute('active', '')

    const span = document.createElement('span')
    span.classList.add('sr-only')
    span.textContent = label
    button.appendChild(span)

    return button;
  }

  #createSliderNav(count=1) {
    const nav = document.createElement('slider-nav');

    let index = 0;
    while (index < count) {
      const button = this.#createButton(index, index === 0)
      nav.appendChild(button)
      this.#buttonHandler(button, index)
      index++
    }

    this.element.appendChild(nav)
  }
}
