/*
  User Consent Management
*/

const userConsent = {
  defaultConsent: {
    analyticsStorage: false,
    adStorage: false,
    adUserData: false,
    adPersonalization: false,
    functionalityStorage: true,
    personalizationStorage: false,
    securityStorage: false
  },

  getCookie() {
    const cookies = Object.fromEntries(document.cookie.split('; ').map(v=>v.split(/=(.*)/s).map(decodeURIComponent)))
    if (!cookies['user_consent']) return undefined
    return JSON.parse(cookies['user_consent'])
  },

  setCookie(value) {
    const encodedValue = encodeURIComponent(JSON.stringify(value))
    const expiry = new Date()
    expiry.setTime(expiry.getTime() + (365 * 24 * 3600 * 1000))
    document.cookie = `user_consent=${encodedValue}; expires=${expiry.toUTCString()}; path=/`
  },

  sendToGtm() {
    const consent = userConsent.getCookie()
    userConsent.gtag('consent', 'update', userConsent.gtmConsent(consent || userConsent.defaultConsent))
    userConsent.gtag('event', 'cookie_consent_update')
  },

  start() {
    document.addEventListener('turbo:load', () => {
      userConsent.gtag('consent', 'default', userConsent.gtmConsent(userConsent.defaultConsent))
      userConsent.sendToGtm()
    })
  },

  gtmConsent(consent) {
    return Object.fromEntries(
      Object.entries(consent).map(
        ([k, v]) => [k.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase(), v ? 'granted' : 'denied']
      )
    )
  },

  gtag(){ dataLayer?.push(arguments) }
}

export default userConsent