import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { frameId: String }

  connect() {
    document.getElementById(this.frameIdValue).addEventListener('turbo:frame-render', (event) => {
      const headers = event.detail.fetchResponse.response.headers
      this.activate(headers.get('X-Controller'), headers.get('X-Action'))
    })
  }

  activate(controller, action) {
    this.element.querySelectorAll('[data-active-for-route]').forEach((elmnt) => {
      const routes = elmnt.dataset.activeForRoute.split(' ')

      routes.some((route) => {
        let c, a
        [c, a] = route.split('#')
        const match = c == controller && (!a || a == action)
        elmnt.toggleAttribute('active', match)
        return match
      })
    })
  }
}
