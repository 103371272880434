/*
  Toast message
*/

export default class Toast {
  constructor(message, options = {}) {
    this.message = message
    this.type = options.type || 'notice'

    this.element = this.buildNode()
  }

  show() {
    document.body.appendChild(this.element)
  }

  close() {
    this.element.remove()
  }

  buildNode() {
    const node = document.createElement('toast-component')
    node.innerHTML = this.#template(this.type)
    node.addEventListener('animationend', () => this.close())
    node.querySelector('button').addEventListener('click', () => this.close())
    return node
  }

  #template(type) {
    return `
    <toast-card type=${type}>
      <toast-message>${this.message}</toast-message>
      <button type="button"><font-icon class="cross"></font-icon></button>
    </toast-card>
    `
  }
}
