/*
 CSS is the key. Textarea works with by shadow content ::before to resize easily by CSS and not computed height with JS.

 <div class="textarea" data-controller="form--input--autoresize">
  <textarea row="1" maxlength="200"></textarea>
 </div>
*/

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const textarea = this.element.querySelector('textarea')
    textarea.addEventListener('input', this.#update)
    this.#update({currentTarget: textarea})
  }

  #update({currentTarget}) {
    currentTarget.parentNode.dataset.content = currentTarget.value
  }
}
