import { Controller } from '@hotwired/stimulus'
import noUiSlider from 'nouislider'

export default class extends Controller {
  static targets = ['slider', 'input']

  connect() {
    this.slider = noUiSlider.create(this.sliderTarget, {
      start: this.inputTarget.value,
      connect: 'lower',
      step: 1,
      margin: 5,
      range: { 'min': 0, 'max': 200 }
    })

    this.slider.on('update', (values, handle) => {
      this.sliderTarget.querySelector(`[data-handle='${handle}'`).dataset.label = parseInt(values[handle])
    })

    this.slider.on('set', (values) => {
      this.inputTarget.value = parseInt(values[0])
    })
  }

  setDefaultRadius(event) {
    const radius = event.detail.radius || 50
    this.slider.set(radius)
  }
}
