/*
 UI COMPONENT: Agnostic structure read more content
 ---------------------------------------------------

 Example: Hidden/Show (content totally hidden)
 <div data-controller="collapse--read-more">
   // initialize with hidden attribute
   <div hidden data-collapse--read-more-target="content" class="1">
    CONTENT …
   </div>

   <button data-action="collapse--read-more#toggle">TRIGGER</button>
 </div>

 Example: Multiline with line-clamp css class
 Display Trigger: add data-collapse--read-more-target="trigger"
 <div data-controller="collapse--read-more">

   // Class line-clamp-[x]
   <div data-collapse--read-more-target="content" class="line-clamp-5">
     CONTENT …
   </div>

   <button data-action="collapse--read-more#toggle" data-collapse--read-more-target="trigger">TRIGGER</button>
 </div>

 Trigger: automatic label management with data-attribute.
 Icon: V1 has only one variant (+/-)
 <button class="link-more icon+text-sm" data-action="collapse--read-more#toggle" data-collapse--read-more-target="trigger">
   <font-icon></font-icon>
   <span data-label-more="Voir plus"
   data-label-less="Voir moins"></span>
 </button>
 */
import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'trigger']

  connect() {
    this.#checkDisplay()
  }

  toggle() {
    this.contentTarget.toggleAttribute('hidden')
  }

  toggle_lines() {
    this.contentTarget.classList.toggle('line-clamp-none')
  }

  #checkDisplay() {
    if(!this.hasTriggerTarget) return

    const isSmaller = this.contentTarget.scrollHeight <= this.contentTarget.clientHeight
    this.triggerTarget.toggleAttribute('hidden', isSmaller)
  }
}
