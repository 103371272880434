/*
 UI COMPONENT: Accordion Management
 ---------------------------------------------------
 Triggered by "open" attribute on top-level element. Labels, icons, and sizes are controlled by CSS.

 <collapse-accordion data-controller="collapse--accordion" open>
    <button
          data-action="collapse--accordion#toggle"
          type="button"
          class="accordion-trigger">
      <span class="accordion-label"
            data-more="More Text Label"
            data-less="Less Text Label"></span>
    </button>
    <accordion-panel>HTML CONTENT…</accordion-panel>
 </collapse-accordion>

 Status (Attribute as Summary Element)
 ---------------------------------------------------
 Open Accordion - attribute "open"
 */
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  toggle() {
    this.element.toggleAttribute('open')

    if (this.element.hasAttribute('open')) {
      this.element.querySelector('button').scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }
}
