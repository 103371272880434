import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.form = this.element.closest('form')
  }

  connect() {
    this.update()
  }

  update() {
    const userTypes = [...this.form.querySelectorAll('[data-user-type-id]:checked')].map(cb => parseInt(cb.dataset.userTypeId))

    if (userTypes.length == 1 && userTypes.includes(2)) {
      this.#toggleFemale(false)
      this.#toggleMale(true)
    } else if (!userTypes.includes(1) && !userTypes.includes(2) && userTypes.length > 0) {
      this.#toggleFemale(true)
      this.#toggleMale(false)
    } else {
      this.#toggleFemale(false)
      this.#toggleMale(false)
    }
  }

  #toggleFemale(disabled) {
    this.#toggle('female', disabled)
  }

  #toggleMale(disabled) {
    this.#toggle('male', disabled)
  }

  #toggle(type, disabled) {
    this.form.querySelectorAll(`[data-disabled-for-gender="${type}"]`).forEach(e => e.toggleAttribute('disabled', disabled))
  }
}
