import { Controller } from '@hotwired/stimulus'

/*
  Custom progress or Rails progress ?
  let xhr = new XMLHttpRequest();
  xhr.open("POST", url);
  xhr.upload.addEventListener("progress", ({loaded, total}) =>{})
*/
export default class FileUploaderController extends Controller {
  static targets = [ 'file', 'button' ]

  connect() {
    this.element.addEventListener('turbo:submit-start', () => this.#submitStart(event))
    this.element.addEventListener('turbo:submit-end', () => this.#submitEnd(event))
  }

  browse() {
    this.fileTarget.click()
  }

  submit() {
    this.element.requestSubmit()
  }

  #submitStart() {
    this.buttonText = this.buttonTarget.innerText
    this.buttonTarget.innerText = I18n.t('js.upload.lock')
    this.buttonTarget.toggleAttribute('disabled', true)
  }

  #submitEnd() {
    this.buttonTarget.innerText = this.buttonText
    this.buttonTarget.toggleAttribute('disabled', false)
  }
}
