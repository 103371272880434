/*
<upload-media data-controller="upload-media">
  <upload-trigger>
    <%= f.file_field :avatar, id: 'uploaded_file', data: { action: 'upload-media#preview' } %>
    <%= f.label :avatar, t('buttons.add_photo'), class: 'my-8 flex justify-center text-neutral-medium icon+text-label-md' do %>
      <font-icon class="upload"></font-icon> <%= t 'buttons.download_photo' %>
    <%end %>
    <%= hidden_field_tag :delete_file, false, data: { upload_media_target: 'delete' } %>
    <img data-upload-media-target="preview">
  </upload-trigger>
  <button class="icon+text-label-md m-auto text-neutral-medium" type="button" data-action="upload-media#delete">
    <font-icon class="trash"></font-icon>
    <strong><%= t 'buttons.delete' %></strong>
  </button>
  <%= error_message_for f, :avatar %>
</upload-media>
*/

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['preview', 'delete']

  connect() {
    this.#setPreviewActive(this.previewTarget.src)
  }

  preview(event) {
    const node = event.currentTarget
    const file = node.files[0]
    if (!file) return

    if (file.type.startsWith('image/'))
      this.#previewImage(file)
    else if (file.type.startsWith('video/'))
      this.#previewVideo(file, node.dataset.previewFallback)
    else
      this.#setPreviewActive(false)
  }

  delete() {
    this.#setPreviewActive(false)
    this.previewTarget.src = ''
  }

  #previewImage(file) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      this.previewTarget.src = event.target.result
      this.#setPreviewActive(true)
    }
  }

  #previewVideo(file, fallback) {
    const video = document.createElement('video')

    // Fire a seek event to make it work on Chrome
    video.addEventListener('loadedmetadata', () => {
      video.currentTime = video.duration > 5 ? 5 : video.duration / 2
    })

    video.addEventListener('seeked', () => {
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      canvas.toBlob((blob) => {
        this.previewTarget.src = URL.createObjectURL(blob)
      }, 'image/jpeg', 0.6)
      this.#setPreviewActive(true)
    }, { once: true })

    if (fallback) {
      video.addEventListener('error', () => {
        this.previewTarget.src = fallback
        this.#setPreviewActive(true)
      })
    }

    video.src = URL.createObjectURL(file)
  }

  #setPreviewActive(active = false) {
    this.element.toggleAttribute('uploaded', active)
    if (!active) this.previewTarget.removeAttribute('src')
    if (this.hasDeleteTarget)
      this.deleteTarget.value = !active
  }
}
