import { Controller } from '@hotwired/stimulus'
import geolocation from 'libs/geolocation'
import Toast from 'libs/toast'
import { i18n } from 'locales/i18n'

class GeolocationController extends Controller {
  static AVAILABLE = 1
  static UNAVAILABLE = 2
  static DENIED = 3
  static targets = ['geolocation', 'city', 'error', 'citySearch', 'radius']
  static values = { searchCityUrl: String }

  positionStatus = GeolocationController.UNAVAILABLE
  #boundPositionUpdated
  #boundPositionDenied
  #boundPositionUnavailable

  initialize() {
    this.#boundPositionUpdated = this.#positionUpdated.bind(this)
    this.#boundPositionDenied = this.#positionDenied.bind(this)
    this.#boundPositionUnavailable = this.#positionUnavailable.bind(this)
  }

  connect() {
    document.addEventListener('position:updated', this.#boundPositionUpdated)
    document.addEventListener('position:denied', this.#boundPositionDenied)
    document.addEventListener('position:unavailable', this.#boundPositionUnavailable)

    if (geolocation.isAvailable())
      this.#updateStatus(GeolocationController.AVAILABLE)
    else
      this.startGeolocation()
  }

  disconnect() {
    document.removeEventListener('position:updated', this.#boundPositionUpdated)
    document.removeEventListener('position:denied', this.#boundPositionDenied)
    document.removeEventListener('position:unavailable', this.#boundPositionUnavailable)
  }

  geolocationMode(){
    this.geolocationTarget.querySelector('input[type="radio"]').checked = true
    this.citySearchTarget.toggleAttribute('hidden', true)
    if (this.positionStatus == GeolocationController.DENIED)
      this.startGeolocation()
  }

  cityMode(){
    this.cityTarget.querySelector('input[type="radio"]').checked = true
    this.citySearchTarget.removeAttribute('hidden')
    this.radiusTarget.dispatchEvent(new CustomEvent('update-radius', { detail: { radius: 50 } }))
  }

  startGeolocation() {
    this.geolocationTarget.toggleAttribute('progress', true)
    geolocation.start()
  }

  searchCity(event) {
    const keys = /^[a-zA-Z\s]{1}$/
    if (!event.key?.match(keys)) return

    const url = new URL(this.searchCityUrlValue)
    const searchParams = new URLSearchParams()
    searchParams.set('name', this.citySearchTarget.querySelector('input').value)
    url.search = searchParams.toString()
    fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.json())
      .then(data => {
        const list = this.citySearchTarget.querySelector('datalist')
        list.replaceChildren()
        data.forEach((city) => {
          let option = document.createElement('option')
          option.value = city.label
          option.dataset.id = city.id
          list.appendChild(option)
        })
      })
  }

  updateCityId(event) {
    if (event.inputType && event.inputType != 'insertReplacementText') return
    const cityName = event.currentTarget.value
    const cityId = this.citySearchTarget.querySelector('datalist').querySelector(`option[value="${cityName}"]`)?.dataset?.id
    this.cityTarget.querySelector('input').value = cityId
  }

  #updateStatus(status) {
    this.positionStatus = status
    this.geolocationTarget.removeAttribute('progress')

    if (this.positionStatus == GeolocationController.AVAILABLE) {
      this.errorTarget.toggleAttribute('hidden', true)
      this.geolocationTarget.removeAttribute('unavailable')
    } else {
      //this.errorTarget.textContent = i18n.t(`js.geolocation.error.${this.positionStatus == GeolocationController.UNAVAILABLE ? 'unavailable' : 'denied'}`)
      this.errorTarget.removeAttribute('hidden')
      //new Toast(i18n.t('js.geolocation.error.toast', 'alert')).show()
      this.geolocationTarget.toggleAttribute('unavailable', true)
      this.cityMode()
    }
  }

  #positionUpdated(event) {
    this.#updateStatus(GeolocationController.AVAILABLE)
  }

  #positionUnavailable(event) {
    this.#updateStatus(GeolocationController.UNAVAILABLE)
  }

  #positionDenied(event) {
    this.#updateStatus(GeolocationController.DENIED)
  }
}

export default GeolocationController
