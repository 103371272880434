import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { event: String, target: String }

  dispatch() {
    const target = this.hasTargetValue ? document.querySelector(this.targetValue) : document
    target.dispatchEvent(new Event(this.eventValue))
  }
}
