import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['panel', 'validator']
  connect() {
    this.element.querySelectorAll('input[type=radio]').forEach( radio => {
      radio.addEventListener('change', (event) => this.#toggle(event))
    })
  }

  disconnect() {
    this.element.querySelectorAll('input[type=radio]').forEach( radio => {
      radio.removeEventListener('change', (event) => this.#toggle(event))
    })
  }

  #toggle(event) {
    this.panelTarget.toggleAttribute('hidden', event.currentTarget!==this.validatorTarget)
  }
}
