import { Controller } from '@hotwired/stimulus'
import Toast from 'libs/toast'

export default class extends Controller {
  open() {
    this._toggle(true)
  }

  close(event) {
    const response = event.detail?.fetchResponse?.response

    if (event.type == 'turbo:submit-end') {
      const headers = response.headers
      if (headers.get('x-message'))
        new Toast(decodeURIComponent(escape(headers.get('x-message'))), { type: headers.get('x-message-type') }).show()

      if (!event.detail.success) return
      else if (headers.get('redirect-to')) this.#redirect(headers.get('redirect-to'))
    }

    if (response?.status != 206) // Do not close on Partial Content
      this._toggle(false)
  }

  #redirect(url) {
    history.pushState(
      { turbo_frame_history: true },
      '',
      url
    )

    Turbo.visit(url)
  }

  _toggle(state) {
    this.element.toggleAttribute('hidden', !state)
    document.body.classList.toggle('modal-is-open', state) // Main scroll lock
  }
}
