import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', {
  state: () => {
    return {
      chatMessages: 0,
      pmessages: 0,
      kisses: 0,
      formViews: 0,
      friendships: 0,
      testimonials: 0,
      socialNotifications: 0,
      events: 0
    }
  },
  actions: {
    set(field, value) {
      this[field] = value
    },

    increment(field) {
      this[field]++
      return this[field]
    },

    decrement(field) {
      if (this[field] > 0)
        this[field]--
      return this[field]
    }
  }
})
