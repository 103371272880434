import { StreamActions } from '@hotwired/turbo'
import Dialog from 'libs/dialog'

StreamActions.dialog = function() {
  const message = this.templateContent
  const title = this.getAttribute('title')
  const redirectTo = this.getAttribute('redirect_to')
  const okLabel = this.getAttribute('ok_label')

  new Dialog(message, { title: title, okLabel: okLabel }).show().then(() => {
    if (!redirectTo) return

    if (window.ReactNativeWebView) {
      window.location.href = redirectTo
    } else {
      Turbo.visit(redirectTo)
    }
  })
}
