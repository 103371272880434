import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  offset = 24

  initialize() {
    this.observer = null
  }

  connect() {
    this.#scrollIntoView(this.element.querySelector('[active]'))

    this.observer = new MutationObserver((mutationList, _observer) => {
      mutationList.some((mutation) => {
        const match = mutation.type == 'attributes' && mutation.attributeName == 'active' && mutation.target.hasAttribute('active')
        if (match) this.#scrollIntoView(mutation.target)
        return match
      })
    })
    this.observer.observe(this.element, { attributes: true, subtree: true, attributeFilter: ['active'] })
  }

  disconnect() {
    this.observer.disconnect()
  }

  #scrollIntoView(element) {
    if (!element) return

    const container = element.parentNode
    const child_offsetRight = element.offsetLeft + element.offsetWidth;
    const container_scrollRight = container.scrollLeft + container.offsetWidth

    if (container.scrollLeft > element.offsetLeft) {
      container.scrollLeft = element.offsetLeft - this.offset
    } else if (container_scrollRight < child_offsetRight) {
      container.scrollLeft += child_offsetRight - container_scrollRight + this.offset
    }
  }
}
