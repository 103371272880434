import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener("resize", () => {
      this.#resize()
    })
  }

  #resize(){
    const video = this.element
    const width = video.videoWidth
    const height = video.videoHeight
    video.style.maxWidth = `${width}px`
    video.style.maxHeight = `${height}px`
  }
}
