import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  open({ params: { url} }) {
    const layer = document.createElement('layer-video')
    layer.innerHTML = this.#build(url)
    document.body.appendChild(layer)
  }

  #build(url) {
    return `
    <video data-controller="video--player" autoplay loop controls controlsList="nodownload nofullscreen noremoteplayback">
      <source src=${url}>
    </video>
    <button onclick="this.parentNode.remove()">
      <font-icon class="cross icon-size-lg"></font-icon>
    </button>
    <overlay-video onclick="this.parentNode.remove()"></overlay-video>
    `
  }
}
