import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js/pure'
import { MeConfig } from 'config.js'

export default class extends Controller {
  static targets = [ 'product', 'stripe', 'stripeElements', 'stripeError', 'stripeSubmit', 'selectedAmount', 'selectedDuration' ]

  connect() {
    if (!this.hasProductTarget) return

    this.paymentIntent = null
    this.stripeElements = null
    this.stripeOptions = JSON.parse(this.stripeTarget.dataset.options)
    delete this.stripeTarget.dataset.options

    loadStripe(MeConfig.stripe.publicKey).then(stripe => this.stripe = stripe)

    this.productTargets.forEach((element) => {
      element.addEventListener('click', () => { this.selectProduct(element) })
    })
  }

  selectProduct(product) {
    this.productTargets.forEach((element) => element.firstElementChild.removeAttribute('active'))
    product.firstElementChild.toggleAttribute('active', true)

    this.#disableStripeSubmit()

    const url = new URL(product.dataset.url)
    if (this.paymentIntent) // Reuse existing intent if exists
      url.search = new URLSearchParams({ stripe_pi: this.paymentIntent }).toString()

    fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.json())
      .then(data => {
        this.selectedDurationTarget.textContent = I18n.t('js.subscriptions.duration')[data.duration]
        this.selectedAmountTarget.textContent = data.amount.toLocaleString(I18n.locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: Math.ceil(data.amount % 1) * 2 })

        if (this.stripeElements) {
          this.stripeElements.fetchUpdates().then(() => {
            this.#enableStripeSubmit();
            this.stripeTarget.scrollIntoView({ behavior: 'smooth' })
          })
        } else {
          this.#initStripeElements(data.client_secret)
        }
      })
  }

  #initStripeElements(clientSecret){
    this.stripeElements = this.stripe.elements({
      clientSecret,
      appearance: { variables: { colorPrimary: window.getComputedStyle(document.documentElement).getPropertyValue('--color-payment-form') } }
    })

    const paymentElements = this.stripeElements.create('payment', { fields: { billingDetails: { name: 'auto', email: 'never', address: 'never' }  }, terms: { card: 'never' } });
    paymentElements.on('ready', () => {
      this.stripeTarget.removeAttribute('hidden')
      this.#enableStripeSubmit()
      this.stripeTarget.scrollIntoView({ behavior: 'smooth' })
    })
    paymentElements.mount(this.stripeElementsTarget)

    const paymentIntent = clientSecret.split('_secret_')[0]

    this.stripeTarget.querySelector('form').addEventListener('submit', (event) => {
      event.preventDefault()
      this.#disableStripeSubmit();

      this.stripe.confirmPayment({
        elements: this.stripeElements,
        confirmParams: {
          return_url: this.stripeOptions.returnUrl,
          payment_method_data: {
            billing_details: {
              email: this.stripeOptions.user_email, phone: '',
              address: { city: '', country: this.stripeOptions.user_country, line1: '', line2: '', postal_code: '', state: ''  }
            }
          }
        }
      }).then((result) => {
        if (result.error) {
          if (result.error.type !== 'validation_error') { this.stripeError.textContent = result.error.message }
          this.#enableStripeSubmit()
        }
      })
    })
  }

  #enableStripeSubmit(){
    this.stripeSubmitTarget.removeAttribute('disabled')
  }

  #disableStripeSubmit(){
    this.stripeSubmitTarget.toggleAttribute('disabled', true)
  }
}
