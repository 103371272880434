import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    window.addEventListener('scroll', () => this.#checkScroll())
  }

  disconnect() {
    window.removeEventListener('scroll', () => this.#checkScroll())
  }

  go () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  #checkScroll() {
    this.#display(window.scrollY > window.innerHeight * 2)
  }

  #display (toggle) {
    this.element.toggleAttribute('show', toggle)
  }
}
