import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['day', 'month', 'year', 'error']

  check(event) {
    const birthDate = new Date(this.yearTarget.value, this.monthTarget.value, this.dayTarget.value)
    birthDate.setFullYear(birthDate.getFullYear() + 18)

    if (!this.yearTarget.value) {
      this.errorTarget.classList.remove('hidden')
      event.stopImmediatePropagation()
    } else if (birthDate > new Date()) {
      location.href = 'https://www.google.fr'
    }
  }
}
