import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.buttons = this.element.querySelectorAll('switch-nav > button')
    this.sections = this.element.querySelectorAll('info-group')

    this.buttons.forEach(button => button.addEventListener('click', () => {
      this.#toggleInfos()
      this.#toggleButtons(button.dataset.target)
    }))
  }

  #toggleButtons (target) {
    this.buttons.forEach( button => button.toggleAttribute('active', target === button.dataset.target))
  }

  #toggleInfos () {
    this.sections.forEach( section => section.toggleAttribute('hidden'))
  }
}
