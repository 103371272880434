/*
  UI COMPONENT: Switch tabs/panel management
  ---------------------------------------------------

  <switch-tabs data-controller="tabs--switch-panel">
    <switch-nav>
      <button active data-tabs--switch-panel-target="trigger" data-action="tabs--switch-panel#toggle" type="button" type="switch-tab">
        TAB 1
      </button>
      <button data-tabs--switch-panel-target="trigger" data-action="tabs--switch-panel#toggle" type="button" type="switch-tab">
        TAB 2
      </button>
    </switch-nav>
    <switch-panels>
      <panel data-tabs--switch-panel-target="panel"></panel>
      <panel data-tabs--switch-panel-target="panel" hidden></panel>
    </switch-panels>
  </switch-tabs>

  ---------------------------------------------------
  Open tab - remove attribute "hidden"
  Active button - attribute "active"

 Stimulus API in HTML
 ---------------------------------------------------
 data-tabs--switch-panel-target="trigger"
 data-action="tabs--switch-panel#toggle"
*/
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'panel'];

  toggle({currentTarget}) {
    this.triggerTargets.forEach(button => button.toggleAttribute('active', button === currentTarget))
    this.panelTargets.forEach((panel, index) => panel.toggleAttribute('hidden', this.triggerTargets[index] !== currentTarget))
  }
}
