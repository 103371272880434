import { Controller } from '@hotwired/stimulus'
import Toast from 'libs/toast'

export default class extends Controller {
  static values = { url: String }
  static targets = ['country', 'zip', 'city']

  connect() {
    this.countryTarget.addEventListener('change', (event) => { this.#reset() })
    this.zipTarget.addEventListener('keyup', (event) => { this.#checkZip() })
  }

  #checkZip() {
    if (this.zipTarget.value === '') {
      this.#reset()
      return
    }

    const mask = new RegExp(this.countryTarget.querySelector(':checked').dataset.zipCodeMask)

    if (this.zipTarget.value.match(mask)) {
      this.zipTarget.blur()
      this.#searchCity()
    } else if (this.zipTarget.value.length == 5) {
      new Toast(I18n.t('js.me_location.unknow'), { type: 'alert' }).show()
      this.#reset()
    }
  }

  #searchCity() {
    const url = new URL(this.urlValue)
    url.search = new URLSearchParams({ country_id: this.countryTarget.value, zip_code: this.zipTarget.value }).toString()
    fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.json())
      .then(data => {
        this.cityTarget.replaceChildren()
        if (data.length != 1)
          this.cityTarget.add(new Option(I18n.t(data.length == 0 ? 'js.me_location.unknow' : 'common.choose'), ''))
        data.forEach(city => this.cityTarget.add(new Option(city.name, city.id)))
        this.cityTarget.classList.toggle('one-option', data.length <= 1)
        this.cityTarget.removeAttribute('disabled')
        if (data.length > 1) this.cityTarget.focus()
      })
  }

  #reset() {
    this.zipTarget.value = ''
    this.cityTarget.replaceChildren()
    this.cityTarget.toggleAttribute('disabled', true)
  }
}
