/*

 data-controller="form--limit-choice"
 data-form--limit-choice-max-value="8"

 Example:

 <button-group data-controller="form--limit-choice" data-form--limit-choice-max-value="8">
   <% UserDetail::bitfields[:swinging].keys.each_with_index do |swinging_type, i|%>
   <label class="toggle-button">
   <%= cf.check_box :"swinging_#{swinging_type}" %>
   <span class="icon+text-md"><%= t('users.swinging')[i] %></span>
   </label>
   <% end %>
 </button-group>
 */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = {
    max: Number
  }

  checkboxes = null
   connect() {
     this.checkboxes = [...this.element.querySelectorAll('input[type="checkbox"]')]
     this.element.addEventListener('change', (event) => {
       if (event.target.type === 'checkbox') this.#toggleSwitch()
     })
     this.#toggleSwitch()
   }

   #toggleSwitch() {
     this.checkboxes.map(checkbox => {
       checkbox.parentElement.toggleAttribute('disabled', !checkbox.checked && this.#checkMax() >= this.maxValue)
     })
   }

   #checkMax() {
     return this.checkboxes
     .filter(checkbox => checkbox.checked)
     .reduce((sum, checkbox) => sum + parseInt(checkbox.value, 10), 0)
   }
}
