import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'product', 'newProduct', 'confirm' ]

  connect() {
    this.productTargets.forEach((element) => {
      element.addEventListener('click', () => { this.selectProduct(element) })
    })
  }

  selectProduct(product) {
    this.productTargets.forEach((element) => element.firstElementChild.removeAttribute('active'))
    product.firstElementChild.toggleAttribute('active', true)

    this.newProductTarget.firstElementChild.replaceWith(product.firstElementChild.cloneNode(true))

    this.confirmTarget.querySelector('form').elements['product_id'].value = product.dataset.productId
    this.confirmTarget.removeAttribute('hidden')

    //this.confirmTarget.scrollIntoView({ behavior: 'smooth', block: "end" })

    const elementTop = this.confirmTarget.getBoundingClientRect().top + window.scrollY
    const offset = 55 // 55 = header height - 1px (hr)
    window.scrollTo({ top: elementTop - offset, behavior: 'smooth' })
  }
}
