/*
 UI COMPONENT: Slider in Infotainment Cards
 ---------------------------------------------------

 Stimulus API in HTML
 ---------------------------------------------------
 data-action="slider#prev"
 data-action="slider#next"
 */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  imagesDisplay = 2
  imagesLength
  imageWidth
  scrollIndex = 0
  scroller
  prevButton
  nextButton

  connect() {
    const images = this.element.querySelectorAll('infotainment-picture > img')
    this.imagesLength = images.length
    this.imageWidth = images[0].clientWidth

    this.scroller = this.element.querySelector('infotainment-picture')
    this.scroller.addEventListener('scroll', () => this.#scrollHandler())

    this.prevButton = this.element.querySelector('infotainment-pictures > button.start')
    this.nextButton = this.element.querySelector('infotainment-pictures > button.end')

    this.#reset()
  }

  prev() {
    this.scrollIndex = Math.max(this.scrollIndex - this.imagesDisplay, 0)
    this.#scroll()
  }

  next() {
    this.scrollIndex = Math.min(this.scrollIndex + this.imagesDisplay, this.imagesLength - this.imagesDisplay)
    this.#scroll()
  }

  #scroll() {
    this.scroller.scrollTo({
      left: this.scrollIndex * this.imageWidth,
      behavior: 'smooth'
    })
    this.#updateButtonState()
  }

  #scrollHandler() {
    this.scrollIndex = Math.round(this.scroller.scrollLeft / this.imageWidth)
    this.#updateButtonState()
  }

  #updateButtonState() {
    this.prevButton.disabled = this.scrollIndex === 0
    this.nextButton.disabled = this.scrollIndex >= this.imagesLength - this.imagesDisplay
  }

  #reset() {
    this.scroller.scrollTo({
      left: 0,
    })
    this.#updateButtonState()
  }
}
