/*
 UI COMPONENT: Menu open/close management
 ---------------------------------------------------
 */
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

  // $(document).on 'click', '[data-menu-burger]', (e) ->
  //   $('body').toggleClass 'navigation-menu--open'

  // $(document).on 'click', '[data-menu] a', (e) ->
  //   $('body').removeClass 'navigation-menu--open'
  
  toggle() {
    document.body.toggleAttribute('menu-is-open')
  }
}
