import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "toggles" ]

  connect() {
    const toggles = this.togglesTarget.querySelectorAll('label input[type="radio"]')
    toggles.forEach( toggle => toggle.addEventListener( "click", (event) => this.#toggle(event) ) )
  }

  #toggle(event) {
    this.element.dataset.userType = event.currentTarget.value
  }
}
