/*
  Trigger
  ---------------
  <button type="button" data-controller="action--toggle" data-action--toggle-target-value="#clubs-filter"></button>

  Target
  ---------------
  <modal-slot id="clubs-filter" hidden>content…</modal-slot>
 */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { target: String }

  connect() {
    this.element.addEventListener('click', () => {
      document.querySelector(this.targetValue)?.toggleAttribute('hidden')
    })
  }
}
