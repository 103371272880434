/*
  Geolocation
*/

class Geolocation {
  lat = null
  lng = null
  #watcherHandler = null
  #timestamp = null

  start() {
    if (this.#watcherHandler) return

    this.#watcherHandler = navigator.geolocation.watchPosition(
      (position) => this.#success(position),
      (error) => this.#error(error)
    )
  }

  stop() {
    if (!this.#watcherHandler) return

    navigator.geolocation.clearWatch(this.#watcherHandler)
    this.#watcherHandler = null
    this.#timestamp = null
  }

  restart() {
    this.stop()
    this.start()
  }

  isAvailable() {
    return this.#timestamp != null
  }

  #success(position) {
    this.lat = position.coords.latitude
    this.lng = position.coords.longitude

    if (this.#timestamp && position.timestamp - this.#timestamp < 900000)
      return

    this.#timestamp = position.timestamp
    this.#updatePosition()

    const event = new CustomEvent('position:updated', { detail: { lat: this.lat, lng: this.lng } })
    document.dispatchEvent(event)
  }

  #error(error) {
    if (error.code == error.PERMISSION_DENIED) {
      this.stop()
      document.dispatchEvent(new Event('position:denied'))
    } else if (!this.isAvailable()) {
      document.dispatchEvent(new Event('position:unavailable'))
    }
  }

  #updatePosition() {
    fetch('/update_position', {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify({ lat: this.lat, lng: this.lng })
    })
  }
}

export default new Geolocation()
