import { Controller } from '@hotwired/stimulus';
import { pinia } from 'stores'
import { mapState, mapStores } from 'pinia'
import { createApp } from 'vue'
import { useNotificationsStore } from 'stores/notifications_store'
import { useChatStore } from 'stores/chat_store'

export default class extends Controller {
  initialize() {
    this.app = createApp({
      name: 'NotificationBar',
      compilerOptions: { isCustomElement: tag => tag.includes('-') },
      computed: {
        ...mapState(useNotificationsStore, ['chatMessages', 'pmessages', 'kisses', 'formViews', 'friendships', 'testimonials', 'socialNotifications', 'events']),
        ...mapStores(useChatStore),
        requests() { return this.friendships + this.testimonials },
        all() { return this.kisses + this.formViews + this.friendships + this.testimonials + this.socialNotifications }
      },
      methods: {
        openChat(e) {
          if (this.chatMessages == 0) return
          e.preventDefault()
          return $.me.chat.selectConversationIfExists()
        },

        toggleChat() {
          return $.me.chat.toggle()
        },

        hideChat() {
          return $.me.chat.hide()
        },

        format(value, max) {
          if (max == null) max = 99
          if (value == 0) return ''
          if (value > max) return `${max}+`
          return value.toString()
        }
      }
    }).use(pinia)
  }

  connect() {
    this.app.mount(this.element)
  }

  disconnect() {
    this.app.unmount()
  }
}
