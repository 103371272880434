import { defineStore } from 'pinia'

export const useChatStore = defineStore('chat', {
  state: () => {
    return {
      isConnected: false,
      status: 0, // Disconnected, 1: Connected, 2: Conversations 3: New Messages, 4: WebRTC
      conversationCount: 0,
      isOpened: false,
      call: {
        enabled: false,
        kind: null
      }
    }
  },
  actions: {
    setChatOpen(isOpened) {
      this.isOpened = isOpened
    },

    setCall(enabled, kind) {
      this.call.enabled = enabled
      if (kind)
        this.call.kind = kind
    }
  }
})
