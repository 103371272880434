/*

 // Input drive Current and Length (event input + Init connect)
 <div data-controller="form--input--chars-counter">
  <input data-form--input--chars-counter-target="input" data-action="form--input--chars-counter#update" maxlength="200" />
  <chars-counter>
    <span data-form--input--chars-counter-target="current">0</span>/<span data-form--input--chars-counter-target="length">0</span>
  </chars-counter>
 </div>

 // Need to Reset (example: clearable input)
 <button type="button" data-action="form--input--chars-counter#reset">Reset</button>
 */

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'current', 'length']

  connect() {
    this.lengthTarget.textContent = this.inputTarget.maxLength
    this.update()
  }

  update() {
    this.currentTarget.textContent = this.inputTarget.value.length
  }

  reset() {
    this.currentTarget.textContent = 0
  }
}
