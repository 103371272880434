/*
  Load url with Turbo Stream headers when element
  intersect with viewport or custom container
  ---------------------------------------------------

  <div data-controller="load-on-intersection"
       data-load-on-intersection-url-value="<%= users_path(page: @current_page + 1) %>">
  </div>

  Parameters
  ---------------------------------------------------
  data-load-on-intersection-url-value="url"
  data-load-on-intersection-root-value="#selector" (optional)
  data-load-on-intersection-root-margin-value="200px" (optional)
*/
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String, root: String, rootMargin: String }

  initialize() {
    const options = {
      root: (this.rootValue ? document.querySelector(this.rootValue) : null),
      rootMargin: this.rootMarginValue || '50%'
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.load()
      }
    })
  }

  load() {
    fetch(this.urlValue, { headers: { accept: 'text/vnd.turbo-stream.html', 'X-Requested-With': 'XMLHttpRequest' } })
      .then(response => response.text())
      .then((data) => {
        Turbo.renderStreamMessage(data)
      })
      .then(_ => history.replaceState(history.state, '', this.urlValue))
  }
}
