import { Controller } from '@hotwired/stimulus'
import noUiSlider from 'nouislider'

export default class extends Controller {
  static targets = ['slider', 'inputMin', 'inputMax']

  connect() {
    this.slider = noUiSlider.create(this.sliderTarget, {
      start: [this.inputMinTarget.value, this.inputMaxTarget.value],
      connect: true,
      step: 1,
      margin: 5,
      range: { 'min': 18, 'max': 99 }
    })

    this.slider.on('update', (values, handle) => {
      this.sliderTarget.querySelector(`[data-handle='${handle}'`).dataset.label = parseInt(values[handle])
    })

    this.slider.on('set', (values) => {
      [this.inputMinTarget.value, this.inputMaxTarget.value] = values.map(d => parseInt(d, 10))
    })
  }
}
