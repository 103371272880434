/*
 UI COMPONENT: Gender Filter Accordion management
 ---------------------------------------------------
 Compound Component : List custom Toggle + Linked Accordion + Toggle list
 All part are linked.

 HTML Structure
 ---------------------------------------------------
 <gender-filter data-controller="gender-filter">

  // Part 1 - TOP
  <menu>
    <li>
      <label class="button-toggle-type" data-type="1">
        <%= f.check_box "seek_#{key}".to_sym, {data: data} %>
        <font-icon class="alias user-type1"></font-icon>
        <span class="label">Label</span>
      </label>
    </li>
    [Continue…]
  </menu>

  // Part 2 - BOTTOM
  <div class="gender-filter__accordions">
    <details data-gender-filter-target="accordion" data-type="<%= value %>">
      <summary
        data-gender-filter-index-param=0
        data-action="click->gender-filter#togglePanel:prevent">
        <font-icon status="plus" class="circle-plus--outline"></font-icon>
        <font-icon status="less" class="circle-less--outline"></font-icon>
      </summary>

      // Part 3 - CONTENT (TOGGLE LIST)
      <gender-filter-panel data-gender-filter-target="accordionPanel" data-index="0">
        <label class="toggle-button">
          <%= f.check_box :seek_couple_straight %>
          <span>Label</span>
        </label>
         [Continue…]
      </gender-filter-panel>
   </details>
   [Continue…]
  </div>
 </gender-filter>
 */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['typeCheckbox', 'accordion', 'accordionPanel']

  connect() {
    this.accordionPanelTargets.forEach(
      (panel, index) => {
        panel.querySelectorAll('.toggle-button').forEach(
          button => button.addEventListener('change', () => this.#toggleChoice(panel, index))
        )
        this.#toggleChoice(panel, index);
      }
    )
  }

  toggleType(event) {
    const {index} = event.params
    const checked = event.currentTarget.checked
    const toggles = this.accordionTargets[index]?.querySelectorAll('input[type="checkbox"]')
    toggles?.forEach(toggle => toggle.checked = checked)
  }

  #toggleChoice(panel, index) {
    const checkboxes = panel.querySelectorAll('input[type="checkbox"]')
    this.typeCheckboxTargets[index].checked = [...checkboxes].some(checkbox => checkbox.checked)
    this.typeCheckboxTargets[index].dispatchEvent(new Event('change', { bubbles: true }))
  }

  togglePanel({params: {index}}) {
    this.accordionTargets.forEach((panel, current) => panel.open = index === current ? !panel.open : false)
  }
}
